import React from "react";
import testimonial from "../assets/testimonial.png";
import user from "../assets/user1.jpg";
import "./HomeStarts.css";

const HomeStarts = () => {
  return (
    <section className="custom-bg">
      <div className="container">
        <br />
        <br />
        <div className="row">
          <div className="col-60">
            <h2
              className="hero-title"
              style={{ fontSize: "48px", color: "#ff9800" }}
            >
              "Innovative Business Solutions for Financial Company"
            </h2>
            <div
              className="userInfo row"
              style={{ gap: "15px", color: "white" }}
            >
              <img src={user} />
              <div className="userDetails">
                <h3>George Barker </h3>
                <p style={{ color: "#ff9800", fontWeight: "600" }}>
                  CEO, BC Pulse
                </p>
              </div>
            </div>
          </div>
          <div className="col-40">
            <br />
            <img src={testimonial} alt="" />
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
      </div>
      <section style={{ background: "white", textAlign: "center" }}>
        <hr style={{ color: "black" }} />
        <br /> <br />
        <h2
          className="hero-title"
          style={{
            color: "#020d1f",
            marginBottom: "0",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          Apply for an account in minutes
        </h2>
        <br />
        <p>Get your BC Pulse Trading account today!</p>
        <br /> <br />
        <a href="/login" className="register-btn">
          Get an account !
        </a>
        <br />
        <br /> <br />
        <br />
      </section>
    </section>
  );
};

export default HomeStarts;
