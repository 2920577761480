import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import RightSide from "../components/RightSide";
import "./Deposite.css";
import axios from "axios";
import { UilClipboardNotes } from "@iconscout/react-unicons";
import { useNavigate } from "react-router-dom";

const Deposite = () => {
  const [paymentType, setPaymentType] = useState("");
  const [user, setUser] = useState([]);
  const handleLogout = (e) => {
    axios.defaults.withCredentials = true;
    axios
      .get("https://api.securetransactionlab.com/logout")
      .then((res) => {
        console.log(res.message);
        navigate("/");
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios
      .get("https://api.securetransactionlab.com")
      .then((res) => {
        if (res.data.valid) {
          axios
            .get(`https://api.securetransactionlab.com/users/${res.data.email}`)
            .then((res) => {
              if (res.data) {
                setUser(res.data[0]);
              }
            })
            .catch((error) => console.log(error));
        } else {
          navigate("/login");
        }
      })
      .catch((error) => console.log(error));
  }, []);

  const navigate = useNavigate();

  const handleSent = (e) => {
    e.preventDefault();

    // console.log(info);
    axios
      .post("https://api.securetransactionlab.com/transactions", {
        type: paymentType,
        address:
          paymentType === "BTC"
            ? document.getElementById("paymentLinkBTC").value
            : document.getElementById("paymentLinkUSDT").value,
        transaction: "Deposit",
        status: "pending",
        userId: user.email,
      })
      .then((res) => {
        navigate("/dashboard");
      })
      .catch((err) => console.log(err));
  };
  function copyAddressBTC() {
    var copyText = document.getElementById("paymentLinkBTC");
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices
    navigator.clipboard.writeText(copyText.value);
    alert("Address copied Successfully");
  }

  function copyAddressUSDT() {
    var copyText = document.getElementById("paymentLinkUSDT");
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices
    navigator.clipboard.writeText(copyText.value);
    alert("Address copied Successfully");
  }
  const hanledlePaymentType = (e) => {
    setPaymentType(e.target.value);
    document.getElementById("paymentLinks").classList.remove("hide");
  };
  console.log(user);
  return (
    <div className="App">
      <div className="AppGlass">
        <Sidebar active={"Deposit"} handleLogout={handleLogout} />
        <div className="MainDash">
          <div className="content">
            <h3>DEPOSIT</h3>
            <br />
            <p>
              You can easily deposit money into your account through your
              favorite crypto wallet, below are list of deposit methods we
              currently support we are working on adding more soon.
            </p>
            <p>
              Need help? <b style={{ color: "red" }}>contact Suport now</b>
            </p>
          </div>

          <div className="content" id="selector">
            <h3>Choose your prefered method</h3>
            <select
              value={paymentType}
              name="DepositOptions"
              id="depositOptions"
              onChange={hanledlePaymentType}
            >
              <option selected>CHOOSE AN OPTION</option>
              <option value="USDT">USDT BEP20</option>
              <option value="BTC">BTC</option>
            </select>
            <br />
            <button className="active" id="showWallet">
              Continue
            </button>
          </div>

          <div id="paymentLinks" className="hide">
            {paymentType === "BTC" ? (
              <div className="content" id="BTCwalletLink">
                <h3>Make (BTC) payment to the address below</h3>
                <div className="formholder">
                  <input
                    id="paymentLinkBTC"
                    disabled
                    type="text"
                    value="14HwuG9KkpzSEw2VXW1dfeS7264wQ9dd4y"
                  />
                  <button
                    onClick={copyAddressBTC}
                    className="activex "
                    style={{ width: "fit-content" }}
                  >
                    <UilClipboardNotes />
                  </button>
                </div>

                <br />
                <br />
                <button
                  onClick={handleSent}
                  style={{ width: "120px" }}
                  id="btnSent"
                  className="active"
                >
                  Sent
                </button>
                <p>
                  Your balance updates automatically after payment is made, it
                  usually take less that 3mins for payment to reflect on the
                  dashboard
                </p>
                <p>
                  Need help? <b style={{ color: "red" }}>contact Suport now</b>
                </p>
              </div>
            ) : (
              <div className="content" id="USDTwalletLink">
                <h3>Make (USDT BEP20) payment to the address below</h3>
                <div className="formholder">
                  <input
                    id="paymentLinkUSDT"
                    disabled
                    type="text"
                    value="0xc6323e236b7919d4fc27cdc190ad6694e38084a5"
                  />
                  <button
                    onClick={copyAddressUSDT}
                    className=" no-before"
                    style={{ width: "fit-content" }}
                  >
                    <UilClipboardNotes />
                  </button>
                </div>

                <br />
                <br />
                <button
                  onClick={handleSent}
                  style={{ width: "120px" }}
                  id="btnSent"
                  className="active"
                >
                  Sent
                </button>
                <p>
                  Your balance updates automatically after payment is made, it
                  usually take less that 3mins for payment to reflect on the
                  dashboard
                </p>
                <p>
                  Need help? <b style={{ color: "red" }}>contact Suport now</b>
                </p>
              </div>
            )}
          </div>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default Deposite;
