import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import RightSide from "../components/RightSide";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "../components/Loading";
import "./Users.css";

const initialState = {
  balance: 0,
  id: "",
  card: "",
  country: "",
  currency: "",
  email: "",
  fullname: "",
  ims: "",
  password: "",
};
const UserDetails = () => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(initialState);
  const { balance, card, country, fullname, ims, password, email } = form;
  const id = useParams();
  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios
      .get(`https://api.securetransactionlab.com/users/details/${id.id}`)
      .then((res) => {
        setForm(res.data[0]);
      })
      .catch((error) => console.log(error));
  }, []);
  // console.log(form);
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleBalChange = (e) => {
    setForm({ ...form, balance: Number(e.target.value) });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(form);
    axios.defaults.withCredentials = true;
    const res = await axios
      .put(`https://api.securetransactionlab.com/users/${id.id}`, form)
      .then((res) => {
        if (res.status === 200) {
          toast.success("User Updated");
        }
        console.log(res.data);
      })
      .catch(function (error) {
        toast.error("User Not Updated try again");
      });
    setLoading(false);
  };

  return (
    <div className="App">
      <div className="AppGlass">
        <Sidebar active={"users"} />
        <div className="MainDash">
          <div className="content">
            <h3>Update User</h3>
            <form className="updateUser" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col">
                  <label htmlFor="name">Full Name</label>
                  <input
                    disabled
                    onChange={handleChange}
                    style={{ width: "90%" }}
                    type="text"
                    name="fullname"
                    value={fullname}
                    placeholder="Full Name"
                    id="name"
                  />
                </div>
                <div className="col">
                  <label htmlFor="email">Email</label>
                  <input
                    name="email"
                    disabled
                    onChange={handleChange}
                    style={{ width: "90%" }}
                    type="email"
                    value={email}
                    id="email"
                    placeholder="email"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label htmlFor="balance">Balance</label>
                  <input
                    onChange={handleBalChange}
                    name="balance"
                    style={{ width: "90%" }}
                    type="number"
                    value={balance}
                    id="balance"
                    placeholder="balance"
                  />
                </div>
                <div className="col">
                  <label htmlFor="crad">Card Activation</label>
                  <input
                    name="card"
                    onChange={handleChange}
                    style={{ width: "90%" }}
                    type="text"
                    value={card}
                    placeholder="entre true or false here"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label htmlFor="crad">Country</label>
                  <input
                    name="country"
                    disabled
                    onChange={handleChange}
                    style={{ width: "90%" }}
                    value={country}
                    type="text"
                    id="country"
                    placeholder="country"
                  />
                </div>
                <div className="col">
                  <label htmlFor="crad">Ims status</label>
                  <input
                    name="ims"
                    onChange={handleChange}
                    style={{ width: "90%" }}
                    type="text"
                    id="ims"
                    value={ims}
                    placeholder="enter true or false here"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label htmlFor="crad">User password</label>
                  <input
                    name="password"
                    disabled
                    onChange={handleChange}
                    style={{ width: "90%" }}
                    type="text"
                    id="passeord"
                    value={password}
                  />
                </div>
              </div>
              <button className="active" type="submit">
                {loading ? <Loading /> : "Update"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
