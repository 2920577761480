import React from "react";
import Sidebar from "../components/Sidebar";
import RightSide from "../components/RightSide";
import { UilCommentAltDots } from "@iconscout/react-unicons";
import "./Card.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Card = () => {
  function handleChat() {
    document.getElementsByClassName("tawk-custom-color")[0].click();
  }
  const navigate = useNavigate();

  const handleLogout = (e) => {
    axios.defaults.withCredentials = true;
    axios
      .get("https://api.securetransactionlab.com/logout")
      .then((res) => {
        console.log(res.message);
        navigate("/");
      })
      .catch((error) => console.log(error));
  };
  return (
    <div className="App">
      <div className="AppGlass">
        <Sidebar active={"Cards"} handleLogout={handleLogout} />
        <div className="MainDash">
          <div className="content">
            <h3>CARD CREATION</h3> <br />
            <p className="info">
              You currently have <b>0</b> active card
            </p>
            <p>
              Create a Master Card for your account, your card will be useful
              for making transfers and online payment, it is also required for
              making transfer on this platform. <br />
              <br />
              <span
                style={{
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#f3c66f",
                  fontWeight: "600",
                }}
              >
                A one time fee will be applied to process your card, click on
                the button below to chat with customer support
              </span>
            </p>
            <button
              id="createCard"
              className="active create"
              onClick={handleChat}
            >
              <div className="x-center">
                Contact support <UilCommentAltDots className="chatIco" />
              </div>
            </button>
          </div>
          {/* <div className="content">
            <h3>Processing Fee</h3>
            <p>
              A one time fee will be applied to process your card, click on the
              button below to chat with customer support
            </p>

            <br />
            <button className="active" id="showWallet"></button>
          </div> */}

          {/* <div className="content" id="BTCwalletLink">
            <h3>Make payment to the address below</h3>
            <input
              id="paymentLinkBTC"
              disabled
              type="text"
              value="t66t7w75yqgwtyb8w7qywb89uibwtw"
            />
            <button
              onClick={copyAddressBTC}
              className="active"
              style={{ width: "fit-content" }}
            >
              Copy
            </button>
            <br />
            <p>
              Your card will be active on your dashboard once the transfer has
              been processed, it usally take about 3 mins to verify and process
              cards once payment is done
            </p>
            <p>
              Need help? <a href="/contact">contact Suport now</a>
            </p>
          </div>

          <div className="content" id="USDTwalletLink">
            <h3>Make payment to the address below</h3>
            <input
              id="paymentLinkUSDT"
              disabled
              type="text"
              value="33t66t7w75yqgwtyb8w7qywb89uibwtw"
            />
            <button
              onClick={copyAddressUSDT}
              className="active"
              style={{ width: "fit-content" }}
            >
              Copy
            </button>
            <br />
            <p>
              Your card will be active on your dashboard once the transfer has
              been processed, it usally take about 3 mins to verify and process
              cards once payment is done
            </p>
            <p>
              Need help? <a href="/contact">contact Suport now</a>
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Card;
