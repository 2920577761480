import React from "react";
import Slider from "react-slick";
import Sec from "../imgs/sec.gif";
import "./Banner.css";

const Banner = () => {
  const settings = {
    vertical: false,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1000,
    arrow: false,
  };

  return (
    <div className="sliders ">
      <div className="backgroundImg2 h-80vh">
        <div className="container ">
          <br />
          <br />
          <br />
          <div className="row">
            <div className="heroContent">
              <h2 className="hero-title ">
                <span style={{ color: "#FF9800" }}>HASSLE</span> FREE,
                <span style={{ color: "#ffcb46" }}> SECURE</span> AND{" "}
                <span style={{ color: "#FF9800" }}>TRANSPARIENT </span> BANKING.{" "}
              </h2>
              <br />
              {/* <p className="hero-text">
                  Innovation objectives are goals to improve things by an order
                  of magnitude. Innovation typically requires experimentation,
                  risk taking and creativity. As such, innovation objectives may
                  involve greater levels of uncertainty than a typical business
                  objective that aims for predictable and quickly obtainable
                  improvements.
                </p> */}
              <br /> <br />
              <a href="/register">
                <button className="g-started">Get Started</button>
              </a>
            </div>
            <div className="lottie">
              <img style={{ width: "100%" }} src={Sec} alt="" />
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default Banner;
