import React from "react";
import Cards from "./Cards";
import "./MainDash.css";
import TableItems from "./Table";
import { Link } from "react-router-dom";

const MainDash = ({ user }) => {
  return (
    <div className="MainDash">
      {user ? (
        <h1 className="wlc-msg">
          Hi, <span style={{ color: "#fca41e" }}>{user.fullname}</span>
        </h1>
      ) : (
        <h1>Welcome</h1>
      )}
      <div className="wlc-msg">
        <b style={{ color: "white" }}>Account No:</b>{" "}
        <span style={{ color: "white" }}>{user.accountNo}</span>
      </div>

      <br />
      <Cards user={user} />
      <TableItems />
      <br />
      <div className="seemore" style={{ textAlign: "center" }}>
        <Link to="/dashboard/transactions">
          <button style={{ margin: "0 auto" }} className="active">
            SEE MORE
          </button>
        </Link>
      </div>
      <br />
    </div>
  );
};

export default MainDash;
