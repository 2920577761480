import React from "react";
import "./HnderBanner.css";
import img1 from "../imgs/globe1.jpg";
import img2 from "../imgs/globe2.jpg";
import { UilShieldCheck } from "@iconscout/react-unicons";

const HnderBanner = () => {
  return (
    <div
      className="custom-bg"
      style={{
        // backgroundImage: `url(${img1})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right",
      }}
    >
      <br />
      <br />
      <br />
      <div className="container">
        <div className="row" style={{ gap: "30px", flexDirection: "column" }}>
          <div className="col-1">
            <h2 className="heading">
              Freelancers,{" "}
              <span style={{ color: " #ff9800" }}>entrepreneurs</span>, and
              <span style={{ color: "#ff9800" }}> sole</span> traders
              <span style={{ color: " #ff9800" }}>.</span>
            </h2>
            <p className="desc">
              Do more than just bank. Access your account from multiple
              platforms, transfer, request for quick loans and more.
            </p>
            <br />
            <br />
            <div className="list-content">
              <div className="row wrap">
                <div className="list">
                  <UilShieldCheck />
                  Wire Transfer
                </div>
                <div className="list">
                  <UilShieldCheck />
                  Full Data Privacy Compliance
                </div>
                <div className="list">
                  <UilShieldCheck />
                  100% Transparent
                </div>
                <div className="list">
                  <UilShieldCheck />
                  Commitment Free
                </div>
                <div className="list">
                  <UilShieldCheck />
                  Real Time Overview
                </div>
                <div className="list">
                  <UilShieldCheck />
                  24/7 Customer Support
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default HnderBanner;
