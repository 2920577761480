import React from "react";
import Sidebar from "../components/Sidebar";
import RightSide from "../components/RightSide";
import TransactionFull from "../components/TransactionFull";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Transaction = () => {
  const navigate = useNavigate();
  const handleLogout = (e) => {
    axios.defaults.withCredentials = true;
    axios
      .get("https://api.securetransactionlab.com/logout")
      .then((res) => {
        console.log(res.message);
        navigate("/");
      })
      .catch((error) => console.log(error));
  };
  return (
    <div className="App">
      <div className="AppGlass">
        <Sidebar active={"Transactions"} handleLogout={handleLogout} />
        <div className="MainDash">
          <TransactionFull />
        </div>
      </div>
    </div>
  );
};

export default Transaction;
