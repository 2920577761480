import React from "react";
import "./About.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

const About = () => {
  return (
    <div className="home-bg">
      <Header />
      <br />
      <br />
      <br />
      <section className="about">
        <div className="container">
          <h2
            className="heading"
            style={{ textAlign: "center", textTransform: "uppercase" }}
          >
            Welcome to <span style={{ color: "#ff9800" }}>STBank</span>
          </h2>

          <div className="about-content" style={{ color: "white" }}>
            <br />
            <p>
              Welcome to Secure Transaction Bank At Secure Transaction Bank, we
              believe in the power of financial empowerment. Established with a
              commitment to serve our community, we have been a trusted partner
              in financial success for individuals, families, and businesses
              alike. Our History Since our inception 2002, we have continuously
              evolved to meet the changing needs of our customers. Over the
              years, we have built a strong foundation based on integrity,
              reliability, and innovation. From traditional banking services to
              cutting-edge financial solutions, we have always been at the
              forefront of the industry.
              <br /> <br />
              Our Mission Our mission is simple: to provide our customers with
              the tools and resources they need to achieve their financial
              goals. Whether it's buying a home, saving for education, or
              planning for retirement, we are dedicated to helping our customers
              navigate their financial journey with confidence and peace of
              mind. Our Values
              <br /> <br />
              Integrity: We uphold the highest ethical standards in everything
              we do, earning the trust and respect of our customers and
              communities. Excellence: We strive for excellence in all aspects
              of our operations, delivering superior products, services, and
              experiences to our customers.
              <br /> <br />
              Innovation: We embrace innovation and technology to drive
              continuous improvement and enhance the customer experience.
              Community: We are committed to making a positive impact in the
              communities we serve, supporting local initiatives and
              organizations that make a difference.
            </p>{" "}
            <br />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default About;
