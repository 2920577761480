import React from "react";
import "./HomeServices.css";
import { UilLifeRing } from "@iconscout/react-unicons";

const HomeServices = () => {
  return (
    <section className="white-bg">
      <br />
      <br />
      <div className="container">
        <h2 className="hero-title">
          Take A <span style={{ color: "#ff9800" }}>Look At </span> Our Service
        </h2>
        <hr className="header-line" />

        <div className="row card-row">
          <div className="col-3">
            <div className="row">
              <div className="icon">
                <UilLifeRing />
              </div>
              <div className="text">
                <h3>Business Banking</h3>
                For businesses, we offer a range of specialized services
                tailored to your unique needs. This includes business checking
                and savings accounts etc.
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="row">
              <div className="icon">
                <UilLifeRing />
              </div>
              <div className="text">
                <h3>Investment Services</h3>
                We offer investment services to help you grow your wealth over
                the long term. This may include access to brokerage accounts,
                mutual funds, retirement etc.
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="row">
              <div className="icon">
                <UilLifeRing />
              </div>
              <div className="text">
                <h3>Financial Planning and Advice</h3>
                We offer financial planning and advisory services to help
                customers set and achieve their financial goals. This may
                include budgeting assistance etc.
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </section>
  );
};

export default HomeServices;
