import React, { useEffect, useState } from "react";
import "./Sidebar.css";
// import Logo from "../imgs/logo.png";
import { UilSignOutAlt, UilUsersAlt, UilBars } from "@iconscout/react-unicons";
import { SidebarData } from "../data/Data";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Logo from "../imgs/stbankLogo.png";
import axios from "axios";
const Sidebar = ({ active, handleLogout }) => {
  const [selected, setSelected] = useState(0);
  const [user, setUser] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios
      .get("https://api.securetransactionlab.com")
      .then((res) => {
        if (res.data.valid) {
          axios
            .get(`https://api.securetransactionlab.com/users/${res.data.email}`)
            .then((res) => {
              if (res.data) {
                setUser(res.data[0]);
              }
            })
            .catch((error) => console.log(error));
        } else {
          navigate("/login");
        }
      })
      .catch((error) => console.log(error));
  }, []);

  const [expanded, setExpaned] = useState(true);

  const sidebarVariants = {
    true: {
      left: "0",
    },
    false: {
      left: "-60%",
    },
  };

  return (
    <>
      <div
        className="bars"
        style={expanded ? { left: "60%" } : { left: "5%" }}
        onClick={() => setExpaned(!expanded)}
      >
        <UilBars />
      </div>
      <motion.div
        className="sidebar"
        variants={sidebarVariants}
        animate={window.innerWidth <= 768 ? `${expanded}` : ""}
      >
        {/* logo */}
        <div className="logo">
          <img class="db-logo" src={Logo} alt="logo" />
        </div>

        <div className="menu">
          {SidebarData.map((item, index) => {
            return (
              <div
                className={
                  active === item.heading ? "menuItem active" : "menuItem"
                }
                key={index}
                onClick={() => setSelected(index)}
              >
                <item.icon />
                <a href={item.link}>
                  <span>{item.heading}</span>
                </a>
              </div>
            );
          })}

          {user?.isAdmin === 1 ? (
            <div className="menuItem">
              {<UilUsersAlt />}
              <a href="/dashboard/users">Users</a>
            </div>
          ) : (
            ""
          )}
          {/* signoutIcon */}

          <div className="menuItem" onClick={handleLogout}>
            <UilSignOutAlt /> Logout
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Sidebar;
