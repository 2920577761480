import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const Login = () => {
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();
  axios.defaults.withCredentials = true;
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("https://api.securetransactionlab.com/login", values)
      .then((res) => {
        if (res.data.login) {
          navigate("/dashboard");
        } else {
          const message = res.data.message;
          toast("Incorrect email or password");
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="App">
      <br />
      <br />
      <ToastContainer />
      <div className="content login-container">
        <center>
          <h2>Login</h2>
        </center>
        <form className="login" onSubmit={handleSubmit}>
          <p className="label">Email Address</p>
          <input
            onChange={(e) => setValues({ ...values, email: e.target.value })}
            type="email"
            name="email"
            autoComplete="false"
            placeholder="Enter email address"
          />
          <p className="label">Password</p>
          <input
            onChange={(e) => setValues({ ...values, password: e.target.value })}
            type="password"
            name="password"
            placeholder="Enter password"
          />
          <br />
          <button className="active" type="submit">
            Login
          </button>
          <br />
          <p style={{ textAlign: "center", fontSize: "12px" }}>
            Don't have an account? <a href="/register">Register</a>
          </p>
          <br />
          <p style={{ textAlign: "center", fontSize: "12px" }}>
            <a href="/password-reset">Reset Password</a>
          </p>
        </form>
      </div>
    </div>
  );
};

export default Login;
