import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
const TransactionFull = () => {
  const [transactions, setTransactions] = useState([]);
  const [deposit, setDeposit] = useState([]);
  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios
      .get(`https://api.securetransactionlab.com/transactions/all`)
      .then((res) => {
        setTransactions(res.data);
      })
      .catch((error) => console.log(error));

    axios
      .get(`https://api.securetransactionlab.com/banks/all`)
      .then((res) => {
        setDeposit(res.data);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="Table">
      <br />
      <h3 style={{ color: "white" }}>Recent Deposit Transactions</h3>
      <TableContainer
        component={Paper}
        style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ADDRESS</TableCell>
              <TableCell align="left">TRANSACTION</TableCell>
              <TableCell align="left">TYPE</TableCell>
              <TableCell align="left">STATUS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ color: "white" }}>
            {transactions?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.address}
                </TableCell>
                <TableCell align="left">{row.transaction}</TableCell>
                <TableCell align="left">{row.transactionType}</TableCell>
                <TableCell align="left" className="Details">
                  {row.status}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <h3 style={{ color: "white" }}>Recent Transfer Transactions</h3>
      <TableContainer
        component={Paper}
        style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>AMOUNT</TableCell>
              <TableCell align="left">ADDRESS/ACCOUNT</TableCell>
              <TableCell align="left">TRANSACTION</TableCell>
              <TableCell align="left">TRANSACTION ID</TableCell>
              <TableCell align="left">STATUS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ color: "white" }}>
            {deposit?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.amount}
                </TableCell>
                <TableCell align="left">{row.bankname}</TableCell>
                <TableCell align="left">{row.transaction}</TableCell>
                <TableCell align="left">{row.id}</TableCell>
                <TableCell align="left" className="Details">
                  {row.status}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TransactionFull;
