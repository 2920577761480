import React from "react";
import Header from "../components/Header";
import Banner from "../components/Banner";
import HnderBanner from "../components/HnderBanner";
import HomeServices from "../components/HomeServices";
import HomeStarts from "../components/HomeStarts";
import Footer from "../components/Footer";

const Homepage = () => {
  return (
    <div className="home-bg">
      <Header active={"Home"} />
      <Banner />
      <HnderBanner />
      <HomeServices />
      <HomeStarts />
      <Footer />
    </div>
  );
};

export default Homepage;
