import { Route, Routes } from "react-router-dom";
import "./App.css";
import Dashboard from "./pages/Dashboard";
import Deposite from "./pages/Deposite";

import Card from "./pages/Card";
import Transfer from "./pages/Transfer";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Transaction from "./pages/Transaction";
import Users from "./pages/Users";
import UserDetails from "./pages/UserDetails";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/Header";
import Homepage from "./pages/Homepage";
import About from "./pages/About";
import NotFound from "./pages/NotFound";
import Contact from "./pages/Contact";
import PasswordReset from "./pages/PasswordReset";

function App() {
  return (
    <Routes>
      <Route path="/dashboard/users" element={<Users />} />
      <Route path="/" element={<Homepage />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/dashboard/user/:id" element={<UserDetails />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/dashboard/money-deposit" element={<Deposite />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/dashboard/transactions" element={<Transaction />} />
      <Route path="dashboard/get-card" element={<Card />} />
      <Route path="/dashboard/money-transfer" element={<Transfer />} />
      <Route path="/password-reset" element={<PasswordReset />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
