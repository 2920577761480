import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import MainDash from "../components/MainDash";
import RightSide from "../components/RightSide";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [userEmail, setUserEmail] = useState("");
  const [user, setUser] = useState([]);
  console.log(user);
  const navigate = useNavigate();
  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios
      .get("https://api.securetransactionlab.com")
      .then((res) => {
        if (res.data.valid) {
          axios
            .get(`https://api.securetransactionlab.com/users/${res.data.email}`)
            .then((res) => {
              if (res.data) {
                setUser(res.data[0]);
              }
            })
            .catch((error) => console.log(error));
        } else {
          navigate("/login");
        }
      })
      .catch((error) => console.log(error));
  }, []);

  const handleLogout = (e) => {
    axios.defaults.withCredentials = true;
    axios
      .get("https://api.securetransactionlab.com/logout")
      .then((res) => {
        console.log(res.message);
        navigate("/");
      })
      .catch((error) => console.log(error));
  };
  // console.log(user);
  return (
    <div className="App">
      <div className="AppGlass">
        <Sidebar active={"Dashboard"} handleLogout={handleLogout} />
        <MainDash user={user} />
      </div>
    </div>
  );
};

export default Dashboard;
