import React from "react";
import "./Contact.css";
import { UilMapPin } from "@iconscout/react-unicons";
import { UilEnvelopeUpload } from "@iconscout/react-unicons";
import { UilPhone } from "@iconscout/react-unicons";
import { UilTelegram } from "@iconscout/react-unicons";
import { UilWhatsapp } from "@iconscout/react-unicons";
import Coin from "../assets/coin.jpg";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Contact = () => {
  return (
    <div class="container">
      <Header />
      <span class="big-circle"></span>
      <img src="img/shape.png" class="square" alt="" />
      <div class="form">
        <div class="contact-info">
          <br />
          <br />
          <h3 class="title">Let's get in touch</h3>
          <p class="text">
            we are more than happy to talk to you, you can also reach us via our
            live chat support or using the details below
          </p>

          <div class="info">
            <div class="information">
              <UilMapPin />
              <p>Threadneedle St, London EC2R 8AH, United Kingdom</p>
            </div>
            <div class="information">
              <UilEnvelopeUpload />
              <p>support@securetransactionlab.com</p>
            </div>
          </div>

          <div class="social-media">
            <p>Connect with us :</p>
            <div class="social-icons">
              <a href="#">
                <UilTelegram />
              </a>
              <a href="#">
                <UilWhatsapp />
              </a>
            </div>
          </div>
          <br />
          <br />
        </div>

        <div
          class="contact-form"
          style={{ backgroundImage: `url(${Coin})`, backgroundSize: "cover" }}
        >
          <span class="circle one"></span>
          <span class="circle two"></span>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
