import React from "react";
import "./BalanceCard.css";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { motion, AnimateSharedLayout } from "framer-motion";

// parent Card

const BalanceCard = (props) => {
  return (
    <AnimateSharedLayout>
      <CompactCard param={props} />
    </AnimateSharedLayout>
  );
};

// Compact Card
function CompactCard({ param }) {
  console.log(param);
  const Png = param.png;

  if (param.title === "Transfer") {
    return (
      <a style={{ width: "300px" }} href="/dashboard/money-transfer">
        <motion.div
          className="CompactCard"
          style={{
            background: param.color.backGround,
            boxShadow: param.color.boxShadow,
          }}
          layoutId="expandableCard"
        >
          <div className="radialBar">
            <CircularProgressbar
              value={param.barValue}
              text={`${param.barValue}%`}
            />
            <span>{param.title}</span>
          </div>
          <div className="detail">
            <Png />
            <span>
              {param.user?.currency}
              {" " + param.value}.00
            </span>
            <span>Last 24 hours</span>
          </div>
        </motion.div>
      </a>
    );
  } else {
    return (
      <a style={{ width: "300px" }} href="/dashboard/money-deposit">
        <motion.div
          className="CompactCard"
          style={{
            background: param.color.backGround,
            boxShadow: param.color.boxShadow,
          }}
          layoutId="expandableCard"
        >
          <div className="radialBar">
            <CircularProgressbar
              value={param.barValue}
              text={`${param.barValue}%`}
            />
            <span>{param.title}</span>
          </div>
          <div className="detail">
            <Png />
            <span>
              {param.user?.currency}
              {" " + param.value}.00
            </span>
            <span>Last 24 hours</span>
          </div>
        </motion.div>
      </a>
    );
  }
}
export default BalanceCard;
