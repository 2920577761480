import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import RightSide from "../components/RightSide";
import UserTables from "../components/UserTables";
import axios from "axios";

const Users = () => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios
      .get("https://api.securetransactionlab.com/users")
      .then((res) => {
        setUsers(res.data);
      })
      .catch((error) => console.log(error));
  }, []);
  return (
    <div className="App">
      <div className="AppGlass">
        <Sidebar active={"users"} />
        <div className="MainDash">
          <UserTables users={users} />
        </div>
      </div>
    </div>
  );
};

export default Users;
