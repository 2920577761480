import React from "react";
import "./Cards.css";
import BalanceCard from "./BalanceCard";
import Card from "./Card";

const Cards = ({ user }) => {
  return (
    <div className="Cards">
      <div className={"parentContainer"}>
        <div className="row" style={{ gap: "15px" }}>
          <BalanceCard
            user={user}
            title="Balance"
            color={{
              backGround: "linear-gradient(#fca61f 0%, rgb(249 109 0) 100%)",
              // boxShadow: "0px 10px 20px 0px #e0c6f5",
            }}
            barValue={100}
            value={user?.balance}
            png={"UilUsdSquare"}
            series={{
              name: "Sales",
              data: [31, 40, 28, 51, 42, 109, 100],
            }}
          />
          <BalanceCard
            user={user}
            title="Transfer"
            color={{
              backGround: "linear-gradient(#fca61f 0%, rgb(249 109 0) 100%)",
              // boxShadow: "0px 10px 20px 0px #FDC0C7",
            }}
            barValue={100}
            value={user?.balance}
            png={"UilUsdSquare"}
            series={{
              name: "Sales",
              data: [31, 40, 28, 51, 42, 109, 100],
            }}
          />

          <Card user={user} />
        </div>
      </div>
    </div>
  );
};

export default Cards;
