import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const PasswordReset = () => {
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();
  axios.defaults.withCredentials = true;
  const handleSubmit = (e) => {
    e.preventDefault();
    const navigateLogin = (e) => {
      navigate("/login");
    };
    axios
      .put("https://api.securetransactionlab.com/reset-password", values)
      .then((res) => {
        if (res.data.password) {
          alert(res.data.message);
          setTimeout(navigateLogin(), 3000);
        } else {
          const message = res.data.message;
          toast(message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="App">
      <br />
      <br />
      <ToastContainer />
      <div className="content login-container">
        <center>
          <h2>Password Reset</h2>
        </center>
        <form className="login" onSubmit={handleSubmit}>
          <p className="label">Email Address</p>
          <input
            onChange={(e) => setValues({ ...values, email: e.target.value })}
            type="email"
            name="email"
            autoComplete="false"
            placeholder="Enter email address"
          />
          <p className="label">New Password</p>
          <input
            onChange={(e) => setValues({ ...values, password: e.target.value })}
            type="password"
            name="password"
            placeholder="Enter password"
          />
          <br />
          <button className="active" type="submit">
            Update
          </button>
          <br />
          <p style={{ textAlign: "center", fontSize: "12px" }}>
            Don't have an account? <a href="/register">Register</a>
          </p>
          <br />
        </form>
      </div>
    </div>
  );
};

export default PasswordReset;
