import React, { useEffect } from "react";
// import Updates from "./Updates";
import "./RightSide.css";
// import CustomerReview from "./CustomeReviews";
import { Timeline } from "react-ts-tradingview-widgets";
import { TickerTape } from "react-ts-tradingview-widgets";

const RightSide = () => {
  return (
    <div className="RightSide">
      <div>
        <h3>Updates</h3>
        {/* <TickerTape colorTheme="light"></TickerTape> */}
        <Timeline
          colorTheme="light"
          feedMode="market"
          market="crypto"
          height={400}
          width="100%"
        ></Timeline>
      </div>
      <div></div>
    </div>
  );
};

export default RightSide;
