import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer class="main-footer">
      <br />
      <br />
      <div class="widget-section">
        <div class="auto-container">
          <div class="row clearfix">
            <div class="col-lg-4 col-md-6 col-sm-12 footer-column">
              <div
                id="bullion_our_community-2"
                class="footer-widget widget_bullion_our_community"
              >
                <div class="contact-widget">
                  <div class="widget-title">
                    <h3>Our Community</h3>
                  </div>{" "}
                  <div class="widget-content">
                    <p>
                      Here you'll find regular market updates, expert tips &amp;
                      stories.
                    </p>

                    <h3>Follow us</h3>
                    <ul class="social-links clearfix">
                      <li>
                        <a target="_blank" href="https://www.facebook.com">
                          <i class="fa-brands fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://www.instagram.com">
                          <i class="fa-brands fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://www.pinterest.com">
                          <i class="fa-brands fa-pinterest-square"></i>
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://www.twitter.com">
                          <i class="fa-brands fa-twitter-square"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 footer-column">
              <div id="nav_menu-2" class="footer-widget widget_nav_menu">
                <div class="widget-title">
                  <h3>Useful Links</h3>
                </div>
                <div class=" row menu-useful-links-container">
                  <ul id="menu-useful-links" class="menu">
                    <li
                      id="menu-item-102"
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-102"
                    >
                      <a href="/dashboard">Account</a>
                    </li>
                    <li
                      id="menu-item-103"
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-103"
                    >
                      <a href="/about">About us</a>
                    </li>
                    <li
                      id="menu-item-104"
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-104"
                    >
                      <a href="/contact">Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 footer-column">
              <div
                id="bullion_need_assistance-2"
                class="footer-widget widget_bullion_need_assistance"
              >
                <div class="assistance-widget">
                  <div class="widget-content">
                    <h3>Need assistance?</h3>{" "}
                    <p>
                      We have a live agent waiting to respond to all your
                      complains
                    </p>{" "}
                    <div class="assistance-box">
                      <figure class="assistance-thumb">
                        <img
                          src="https://jan.coderdemo.com/newwp/bullion/wp-content/uploads/2023/03/assistance-1.jpg"
                          alt="Awesome Image"
                        />
                      </figure>
                      <h5>Live Chat With</h5>{" "}
                      <div class="link-box">
                        <a href="#">
                          <span>Expert</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
      <div class="footer-bottom">
        <div class="auto-container">
          <div class="bottom-inner">
            <div class="copyright">
              <p>
                ©{" "}
                <span>
                  2024 <a href="#">BC Famous</a>.
                </span>{" "}
                All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
