import React from "react";
import "./Header.css";
import Flag from "../assets/uk-flag.png";
import Logo from "../imgs/stbankLogo.png";

const Header = ({ active }) => {
  return (
    <div className="  sticky">
      <header className="bc-header">
        <div className="container">
          <div className="bc-row">
            <div className="bc-col hlogo">
              <a href="/">
                <img className="header-logo" src={Logo} />
              </a>
            </div>
            <nav className="bc-nav">
              <ul className="bc-ul">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/about">About</a>
                </li>
                <li>
                  <a href="/contact">Contact</a>
                </li>
              </ul>
            </nav>
            <div className="bc-nav-action">
              <div className="bc-lang">
                <div className="flag-img">
                  <img src={Flag} />
                </div>
              </div>

              <a href="/login">
                <button
                  style={{ cursor: "pointer" }}
                  className=" bc-button register "
                >
                  Sign In
                </button>
              </a>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
