import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import RightSide from "../components/RightSide";
import { UilCheckCircle, UilInfoCircle } from "@iconscout/react-unicons";
import "./Transfer.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loading from "../components/Loading";
import { ToastContainer, toast } from "react-toastify";
const Transfer = () => {
  // bank
  const [bankName, setBankName] = useState("");
  const [bankAcctName, setAcctBankName] = useState("");
  const [bankAcctNo, setBankAcctNo] = useState("");
  const [bankAmount, setBankAmount] = useState("");

  // crypto
  const [cryptoAddress, setCryptoAddress] = useState("");
  const [cyrptoType, setCryptoType] = useState("");
  const [cyrptoAmount, setCryptoAmount] = useState("");

  // paypal
  const [paypalAddress, setPaypalAddress] = useState("");
  const [paypalAmount, setPaypalAmount] = useState("");

  const [paymentMethod, setpaymentMethod] = useState("");
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios
      .get("https://api.securetransactionlab.com")
      .then((res) => {
        if (res.data.valid) {
          axios
            .get(`https://api.securetransactionlab.com/users/${res.data.email}`)
            .then((res) => {
              if (res.data) {
                setUser(res.data[0]);
              }
            })
            .catch((error) => console.log(error));
        } else {
          // navigate("/login");
        }
      })
      .catch((error) => console.log(error));
  });

  const handlePaymentMethod = (e) => {
    document.getElementById("pchannel").style.display = "none";
    if (e.target.value === "CRYPTO") {
      setpaymentMethod("crypto");
      document.getElementById("crypto").classList.remove("hide");
      document.getElementById("paypal").classList.add("hide");
      document.getElementById("bank").classList.add("hide");
    }
    if (e.target.value === "BANK") {
      setpaymentMethod("bank");
      document.getElementById("bank").classList.remove("hide");
      document.getElementById("paypal").classList.add("hide");
      document.getElementById("crypto").classList.add("hide");
    }

    if (e.target.value === "PAYPAL") {
      setpaymentMethod("paypal");
      document.getElementById("paypal").classList.remove("hide");
      document.getElementById("crypto").classList.add("hide");
      document.getElementById("bank").classList.add("hide");
    }
  };

  const handleBank = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(function () {
      console.log(bankAcctName, bankAcctNo, bankAmount, bankName);
      document.getElementById("ims").style.display = "block";
      document.getElementById("bank").style.display = "none";
      setLoading(false);
    }, 3000);
  };

  const handlePaypal = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(function () {
      console.log(paypalAddress, paypalAmount, paymentMethod);
      document.getElementById("ims").style.display = "block";
      document.getElementById("paypal").style.display = "none";
      setLoading(false);
    }, 3000);
  };

  const handleCrypto = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(paypalAddress, paypalAmount, paymentMethod);
    setTimeout(function () {
      document.getElementById("ims").style.display = "block";
      document.getElementById("crypto").style.display = "none";
      setLoading(false);
    }, 3000);
  };
  const handleIms = (e) => {
    e.preventDefault();
    setLoading(true);
    if (user.ims == "true") {
      if (user.card == "false") {
        setTimeout(function () {
          setLoading(false);
          document.getElementById("cardAct").classList.remove("hide");
          document.getElementById("ims").style.display = "none";
        }, 3000);
      } else {
        // submit bank transfer to db
        if (paymentMethod === "bank") {
          const values = {
            bankname: bankName,
            bankaccount: bankAcctName,
            amount: bankAmount,
            acountname: bankAcctName,
            transaction: "Bank Transfer",
            accountno: bankAcctNo,
            status: "pending",
            userId: user?.email,
          };
          axios
            .post(
              "https://api.securetransactionlab.com/transactions/bank",
              values
            )
            .then((res) => {
              console.log(res.data);
              if (res.status === 200) {
                document.getElementById("success").style.display = "block";
                document.getElementById("ims").style.display = "none";
              }
            })
            .catch((err) => console.log(err));
        }
        if (paymentMethod === "paypal") {
          const values = {
            bankname: paypalAddress,
            amount: paypalAmount,
            transaction: "Paypal Transfer",
            status: "pending",
            userId: user?.email,
          };
          console.log(values);
          axios
            .post(
              "https://api.securetransactionlab.com/transactions/bank",
              values
            )
            .then((res) => {
              console.log(res.data);
              if (res.status === 200) {
                document.getElementById("success").style.display = "block";
                document.getElementById("ims").style.display = "none";
              }
            })
            .catch((err) => console.log(err));
        }
        if (paymentMethod === "crypto") {
          const values = {
            bankname: cryptoAddress,
            amount: cyrptoAmount,
            acountname: cyrptoType,
            transaction: "Crypto Transfer",
            status: "pending",
            userId: user?.email,
          };
          axios
            .post(
              "https://api.securetransactionlab.com/transactions/bank",
              values
            )
            .then((res) => {
              console.log(res.data);
              if (res.status === 200) {
                document.getElementById("success").style.display = "block";
                document.getElementById("ims").style.display = "none";
              }
            })
            .catch((err) => console.log(err));
        }
      }
      setLoading(false);
      return toast("Valid IMS");
    } else {
      setLoading(false);
      toast("Invalid IMS");
      return;
    }
  };

  return (
    <div className="App">
      <ToastContainer />
      <div className="AppGlass">
        <Sidebar active={"Transfer"} />
        <div className="MainDash">
          <div className="content">
            <h3>Money Transfer</h3>
            <p>
              You can easily transfer your available money into your bank or
              wallet account, Select the right method to continue.
            </p>
            <p>
              Need help? <b style={{ color: "red" }}>contact Suport now</b>
            </p>
          </div>

          <div id="pchannel" className="content">
            <h3>Prefered Channel</h3>
            <p>Select a prefered channel for your transfer</p>
            <select
              value={paymentMethod}
              name="transferOptions"
              id="transferOptions"
              onChange={handlePaymentMethod}
            >
              <option defaultValue>CHOOSE AN OPTION</option>
              <option value="BANK">BANK</option>
              <option value="PAYPAL">PAYPAL</option>
              <option value="CRYPTO">CRYPTO</option>
            </select>
          </div>

          {/* PAYPAL */}
          <div id="paypal" className="content hide">
            <h3>PayPal Transfer</h3>
            <p>
              Enter the email address you want to transfer to followed by the
              amount to send
            </p>

            <form id="payment" onSubmit={handlePaypal}>
              <input
                required
                type="email"
                placeholder="Enter Email here"
                onChange={(e) => setPaypalAddress(e.target.value)}
              />
              <input
                required
                type="number"
                placeholder="Enter Amount to transfer"
                onChange={(e) => setPaypalAmount(e.target.value)}
              />
              <button className="active" type="submit">
                {loading ? <Loading /> : "Transfer"}
              </button>
            </form>
          </div>

          {/* CRYPTO TRANSFER */}
          <div id="crypto" className="content hide ">
            <h3>Crypto Transfer</h3>
            <p>Select the correct transfer type</p>
            <form className="sendCrypto" onSubmit={handleCrypto}>
              <select
                value={cyrptoType}
                name="transferOptions"
                id="transferOptions"
                onChange={(e) => setCryptoType(e.target.value)}
              >
                <option defaultValue disabled value="">
                  SELECT AN OPTION
                </option>
                <option value="USDT">USDT</option>
                <option value="BTC">BTC</option>
              </select>

              <input
                required
                type="number"
                id="amount"
                placeholder="Enter amount"
                onChange={(e) => setCryptoAmount(e.target.value)}
              />
              <input
                required
                onChange={(e) => setCryptoAddress(e.target.value)}
                type="text"
                id="paymentAddress"
                placeholder="Enter payment address"
              />

              <button className="active">
                {loading ? <Loading /> : "Transfer"}
              </button>
            </form>
          </div>

          {/* BANK TRANSFER  */}
          <div id="bank" className="content hide">
            <h3>Bank Transfer</h3>
            <p>Enter your Bank details to complete the transfer</p>
            <form className="bankTransfer" onSubmit={handleBank}>
              <input
                required
                type="number"
                id="acctname"
                placeholder="Amount to send"
                onChange={(e) => setBankAmount(e.target.value)}
              />
              <input
                required
                onChange={(e) => setBankName(e.target.value)}
                type="text"
                id="bankname"
                placeholder="Bank name"
              />

              <input
                required
                onChange={(e) => setBankAcctNo(e.target.value)}
                type="number"
                id="bankAccount"
                placeholder="Account Number"
              />

              <input
                required
                type="text"
                id="acctname"
                placeholder="Account name"
                onChange={(e) => setAcctBankName(e.target.value)}
              />
              <button type="submit" className="active">
                {loading ? <Loading /> : "Transfer"}
              </button>
            </form>
          </div>

          {/* CARD ACTIVATION MESSAGE  */}
          <div id="cardAct" className="content hide">
            <UilInfoCircle className="fontError" />
            <h3>Activate Card</h3>
            <p>Looks like you haven't activated a card</p>
            <p>
              Quickly activate your card from the card section to complete your
              payment or click <a href="/dashboard/get-card">here</a>
            </p>
          </div>

          {/* SUCCESS  */}
          <div id="success" className="content centered hide">
            <UilCheckCircle className="fontSent" />
            <h3>Payment Request Sent</h3>
            <p>
              Your payment request has been sent and money will be transfered
              after approval, you can view the progress on your dashboard
            </p>
          </div>

          {/* IMS CODE FORM  */}
          <div id="ims" className="content hide">
            <h3>Enter IMS code</h3>
            <p>
              Provide your personal <b>IMS CODE</b> to complete the this
              transfer
            </p>
            <span style={{ fontStyle: "italic", fontSize: "12px" }}>
              contact support to purchase your IMS code, charges apply.
            </span>
            <span
              style={{
                fontStyle: "italic",
                fontSize: "12px",
                marginBottom: "10px",
              }}
            >
              <p>
                Need help? <b style={{ color: "red" }}>contact Suport now</b>
              </p>
            </span>

            <br />
            <form style={{ paddingBlock: "10px" }} onSubmit={handleIms}>
              <input required type="number" placeholder="Enter IMS code" />
              <button type="submit" className="active">
                {loading ? <Loading /> : "Transfer"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transfer;
